import { useState } from 'react';
import LoginSection from '~/components/login/LoginSection';
import Register from '~/pages/Customer/Register';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import closeFilters from '~/images/close-filters.svg';
import { showLoginModal } from '~/store/actions.js';

const Login = () => {
	const { t, i18n } = useTranslation();
	const [showTab1, setShowTab1] = useState(true);
	const [showTab2, setShowTab2] = useState(false);
	const dispatch = useDispatch();

	const toggleClass = e => {
		let classes = 'tab-log';
		let els = document.getElementsByClassName('tab-log active-log-tab');
		if (els) {
			while (els[0]) {
				els[0].classList.remove('active-log-tab');
			}
		}
		e.target.className = classes.replace('tab-log', 'tab-log active-log-tab');
		handleClick();
	};

	const closeLogin = () => {
		document.body.classList.remove('overflow');
		dispatch(showLoginModal(false));
	};

	const handleClick = event => {
		if (document.getElementById('login').classList.contains('active-log-tab')) {
			setShowTab1(true);
			setShowTab2(false);
		} else {
			setShowTab1(false);
			setShowTab2(true);
		}
	};

	return (
		<>
			<div className={showTab1 ? 'login-modal login-content' : 'login-modal register-content'}>
				<main>
					<button type="button" onClick={closeLogin}>
						<img src={closeFilters} />
					</button>
					<ul className="flex-col">
						<li>
							<button id="register" className="tab-log" type="button" onClick={toggleClass}>
								{t('register')}
							</button>
						</li>
						<li>
							<button id="login" className="tab-log active-log-tab" type="button" onClick={toggleClass}>
								{t('sign_in_title')}
							</button>
						</li>
					</ul>

					{showTab1 && <LoginSection closeLogin={closeLogin} />}
					{showTab2 && <Register />}
				</main>
			</div>
		</>
	);
};

export default Login;
