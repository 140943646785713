import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api_getContentPageByCode, api_subscribeToNewsletter } from '~/backend/api_calls';
import { userIsBot } from '~/common/helper';
import { notifyError, notifyInfo } from './ToastMessages';

export default function NewsletterArea() {
	const { t, i18n } = useTranslation();
	const [newsletterEmail, setNewsletterEmail] = useState('');
	const [newsletterConsent, setNewsletterConsent] = useState(false);
	const [termsAndConditionsLinks, setTermsAndConditionsLinks] = useState('');

	useEffect(() => {
		api_getContentPageByCode('terms_and_conditions_links').then(json => setTermsAndConditionsLinks(json));
	}, []);

	const handleFormSubmit = e => {
		e.preventDefault();

		if (newsletterEmail == null || newsletterEmail.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (!newsletterConsent) {
			notifyError('You have to accept the Terms and Conditions');
			return;
		}

		api_subscribeToNewsletter(newsletterEmail)
			.then(json => notifyInfo(json))
			.catch(json => notifyError(json));
	};

	const klaviyoNewsletter = () => {
		if (userIsBot) {
			return null;
		}

		if (localStorage.getItem('I18N_LANGUAGE') === 'el') return <div id="klaviyo-newsletter" className="klaviyo-form-VuqxuF"></div>;

		return <div id="klaviyo-newsletter-en" className="klaviyo-form-R8pNhd"></div>;
	};

	return (
		<div className="newsletter-area">
			<div className="newsletter-box">
				<div className="Klaviyo-content">{klaviyoNewsletter()}</div>
				{/*<h2 className="newsletter-title">{t("footer_newsletter_title")}</h2>*/}
				{/*<p className="newsletter-text" dangerouslySetInnerHTML={{ __html: t("footer_newsletter_description") }}>*/}
				{/*</p>*/}

				{/*<form className="subscription" onSubmit={handleFormSubmit}>*/}
				{/*    <input key="subscription_newsletter" className="add-email" type="email" name="guest-email" placeholder={t("footer_newsletter_input")} value={newsletterEmail} onChange={(e) => setNewsletterEmail(e.target.value)} />*/}

				{/*    <button className="submit-email" type="submit">*/}
				{/*        <span className="before-submit">{t("footer_newsletter_button")}</span>*/}
				{/*    </button>*/}

				{/*</form>*/}
				{/*<div className="checkbox-area">*/}
				{/*    <label className="acceptance">*/}
				{/*        <input type="checkbox" name="accept-terms" checked={newsletterConsent} onChange={(e) => setNewsletterConsent(e.target.checked)} />*/}
				{/*        <span className="input-label">*/}
				{/*            <span*/}
				{/*                dangerouslySetInnerHTML={{*/}
				{/*                    __html: termsAndConditionsLinks.bd*/}
				{/*                }}></span>*/}
				{/*        </span>*/}
				{/*    </label>*/}
				{/*</div>*/}
			</div>
			<div className="social-links">
				<a href="https://www.facebook.com/BODYTALK.BDTK/" title="Facebook" target="_blank" rel="noopener noreferrer">
					<svg id="fb" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<rect id="Rectangle_161019" data-name="Rectangle 161019" width="30" height="30" fill="#fff" opacity="0" />
						<g id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" transform="translate(10 6)">
							<path
								id="Icon_awesome-facebook-f-2"
								data-name="Icon awesome-facebook-f"
								d="M10.954,10.5l.519-3.379H8.231V4.931a1.69,1.69,0,0,1,1.905-1.826H11.61V.228A17.976,17.976,0,0,0,8.993,0C6.323,0,4.578,1.618,4.578,4.548V7.124H1.609V10.5H4.578v8.169H8.231V10.5Z"
								transform="translate(-1.609)"
								fill="#ababab"
							/>
						</g>
					</svg>
				</a>
				<a href="https://www.instagram.com/bodytalk_bdtk/?hl=el" title="Instagram" target="_blank" rel="noopener noreferrer">
					<svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<rect id="Rectangle_161020" data-name="Rectangle 161020" width="30" height="30" fill="#fff" opacity="0" />
						<g id="Icon_simple-instagram" data-name="Icon simple-instagram" transform="translate(5.591 6)">
							<path
								id="Icon_simple-instagram-2"
								data-name="Icon simple-instagram"
								d="M9.336,0C6.8,0,6.483.012,5.487.056A6.884,6.884,0,0,0,3.221.49,4.572,4.572,0,0,0,1.567,1.567,4.555,4.555,0,0,0,.49,3.221,6.864,6.864,0,0,0,.056,5.487C.009,6.483,0,6.8,0,9.336s.012,2.853.056,3.849A6.888,6.888,0,0,0,.49,15.451,4.578,4.578,0,0,0,1.567,17.1a4.565,4.565,0,0,0,1.654,1.077,6.893,6.893,0,0,0,2.266.434c1,.047,1.312.056,3.849.056s2.853-.012,3.849-.056a6.909,6.909,0,0,0,2.266-.434,4.771,4.771,0,0,0,2.731-2.731,6.888,6.888,0,0,0,.434-2.266c.047-1,.056-1.312.056-3.849s-.012-2.853-.056-3.849a6.9,6.9,0,0,0-.434-2.266A4.582,4.582,0,0,0,17.1,1.567,4.549,4.549,0,0,0,15.451.49,6.868,6.868,0,0,0,13.185.056C12.189.009,11.872,0,9.336,0Zm0,1.68c2.492,0,2.789.012,3.773.055a5.144,5.144,0,0,1,1.733.323A3.072,3.072,0,0,1,16.614,3.83a5.155,5.155,0,0,1,.321,1.733c.044.985.054,1.281.054,3.773s-.012,2.789-.058,3.773a5.254,5.254,0,0,1-.328,1.733,2.964,2.964,0,0,1-.7,1.075,2.913,2.913,0,0,1-1.074.7,5.192,5.192,0,0,1-1.739.321c-.991.044-1.283.054-3.78.054s-2.79-.012-3.78-.058a5.294,5.294,0,0,1-1.74-.328,2.891,2.891,0,0,1-1.073-.7,2.835,2.835,0,0,1-.7-1.074,5.3,5.3,0,0,1-.327-1.739c-.035-.98-.047-1.283-.047-3.769s.012-2.79.047-3.782A5.292,5.292,0,0,1,2.02,3.8a2.767,2.767,0,0,1,.7-1.074,2.761,2.761,0,0,1,1.073-.7A5.167,5.167,0,0,1,5.521,1.7c.992-.035,1.284-.047,3.78-.047l.035.023Zm0,2.861A4.794,4.794,0,1,0,14.13,9.336,4.794,4.794,0,0,0,9.336,4.542Zm0,7.906a3.112,3.112,0,1,1,3.112-3.112A3.111,3.111,0,0,1,9.336,12.448Zm6.1-8.095a1.12,1.12,0,1,1-1.12-1.12A1.121,1.121,0,0,1,15.44,4.353Z"
								transform="translate(0)"
								fill="#ababab"
							/>
						</g>
					</svg>
				</a>
				<a href="https://www.youtube.com/channel/UCMUu-xrtZC0Dy503nhwHOCw" title="Youtube" target="_blank" rel="noopener noreferrer">
					<svg id="youtube" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<rect id="Rectangle_161022" data-name="Rectangle 161022" width="30" height="30" fill="#fff" opacity="0" />
						<path
							id="Icon_awesome-youtube"
							data-name="Icon awesome-youtube"
							d="M22.877,6.953a2.8,2.8,0,0,0-1.971-1.984C19.168,4.5,12.2,4.5,12.2,4.5s-6.971,0-8.71.469A2.8,2.8,0,0,0,1.516,6.953a29.385,29.385,0,0,0-.466,5.4,29.385,29.385,0,0,0,.466,5.4,2.759,2.759,0,0,0,1.971,1.952c1.739.469,8.71.469,8.71.469s6.971,0,8.71-.469a2.759,2.759,0,0,0,1.971-1.952,29.385,29.385,0,0,0,.466-5.4,29.385,29.385,0,0,0-.466-5.4ZM9.917,15.668V9.039l5.827,3.315L9.917,15.668Z"
							transform="translate(2.95 2.5)"
							fill="#ababab"
						/>
					</svg>
				</a>
				<a href="https://gr.pinterest.com/bodytalk_bdtk/" title="Pinterest" target="_blank" rel="noopener noreferrer">
					<svg id="pinterest" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<rect id="Rectangle_161022" data-name="Rectangle 161022" width="30" height="30" fill="#fff" opacity="0" />
						<path
							id="Icon_awesome-pinterest"
							data-name="Icon awesome-pinterest"
							d="M21.236,11.181A10.619,10.619,0,0,1,7.476,21.324a11.936,11.936,0,0,0,1.319-2.783c.128-.5.659-2.526.659-2.526a2.844,2.844,0,0,0,2.432,1.22c3.2,0,5.51-2.946,5.51-6.606A6.232,6.232,0,0,0,10.849,4.5c-4.581,0-7.017,3.074-7.017,6.427,0,1.558.831,3.5,2.154,4.115.2.094.308.051.355-.141.034-.146.214-.869.3-1.2a.318.318,0,0,0-.073-.3,4.182,4.182,0,0,1-.784-2.423,4.6,4.6,0,0,1,4.8-4.607,4.192,4.192,0,0,1,4.436,4.32c0,2.873-1.451,4.864-3.34,4.864A1.54,1.54,0,0,1,10.1,13.625a21.336,21.336,0,0,0,.878-3.537A1.333,1.333,0,0,0,9.633,8.595c-1.066,0-1.922,1.1-1.922,2.577a3.826,3.826,0,0,0,.317,1.576s-1.049,4.444-1.242,5.275a10.278,10.278,0,0,0-.039,3.048,10.619,10.619,0,1,1,14.489-9.89Z"
							transform="translate(4.423 3.713)"
							fill="#ababab"
						/>
					</svg>
				</a>
			</div>
		</div>
	);
}
