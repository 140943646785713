import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '~/components/common/Button';
import { notifyError } from '~/components/common/ToastMessages';

const GiftcardStep1 = ({ currentStep, setCurrentStep, metaData, setMetaData }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(1);

	const handleChangeTab = tabId => {
		setActiveTab(tabId);

		if (tabId === 2) {
			setMetaData({ ...metaData, friendName: '' });
		}
	};

	const nextStep = () => {
		if (metaData.name.trim().length === 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (activeTab === 1 && metaData.friendName.trim().length === 0) return;

		setCurrentStep(currentStep + 1);
	};

	return (
		<div className="giftcard-step">
			<div className="first-step">
				<h3>{t('for_whom')}</h3>
				<div className="receiver-tabs">
					<h4 className={`${activeTab === 1 && 'active active-first'}`} onClick={() => handleChangeTab(1)}>
						{t('for_friend')}
					</h4>
					<h4 className={`${activeTab === 2 && 'active active-second'}`} onClick={() => handleChangeTab(2)}>
						{t('for_me')}
					</h4>
				</div>
				{activeTab === 1 && (
					<div className="tab-content first-tab">
						<input
							type="text"
							placeholder={t('my_friend_name')}
							value={metaData.friendName}
							onChange={e => setMetaData({ ...metaData, friendName: e.target.value })}
						/>
						<input
							type="text"
							placeholder={t('my_name')}
							value={metaData.name}
							onChange={e => setMetaData({ ...metaData, name: e.target.value })}
						/>
					</div>
				)}

				{activeTab === 2 && (
					<div className="tab-content second-tab">
						<input
							type="text"
							placeholder={t('my_name')}
							value={metaData.name}
							onChange={e => setMetaData({ ...metaData, name: e.target.value })}
						/>
					</div>
				)}

				<div className="next-step-button">
					<Button color="secondary" actionOnClick={nextStep}>
						{t('next_step')}
					</Button>
				</div>
			</div>
		</div>
	);
};

GiftcardStep1.propTypes = {
	currentStep: PropTypes.number,
	setCurrentStep: PropTypes.func,
	metaData: PropTypes.object,
	setMetaData: PropTypes.func
};

export default GiftcardStep1;
