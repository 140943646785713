import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sendUserInfoGTMEvent } from '~/common/gtm.js';
import logo from '~/images/bdtk-logo.svg';
import safePayment from '~/images/safe-payment.svg';
import step1 from '~/images/step1.svg';
import step2Disabled from '~/images/step2-disabled.svg';
import step2 from '~/images/step2.svg';
import step3Disabled from '~/images/step3-disabled.svg';
import step3 from '~/images/step3.svg';

import { api_getProfile } from '~/backend/api_calls';
import UserState from '~/backend/user_session';

const HeaderCheckout = ({ step }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const isAuthenticated = UserState.isAuthenticated();

	const handleNavigate = toStep => {
		if (toStep > step) return;
		navigate(`/checkout-step-${toStep}`);
	};

	// gtm
	useEffect(() => {
		if (UserState.isAuthenticated()) {
			api_getProfile().then(json => {
				sendUserInfoGTMEvent(json);
			});
		} else if (UserState.getEmail()) {
			sendUserInfoGTMEvent({ eml: UserState.getEmail() });
		}
	}, [isAuthenticated]);

	const shouldDisplaySteps =
		!pathname.includes('/checkout-step-1') && !pathname.includes('/checkout-failure') && !pathname.includes('/checkout-success');

	return (
		<div id="header-checkout">
			<header>
				<div className="content-wrapper">
					<div id="checkout-logo">
						<Link to="/">
							<img src={logo} alt="Bodytalk" loading="lazy" />
						</Link>
					</div>
					<div id="safe-payment" className="flex-col">
						<img src={safePayment} alt="Secure Payment" />
						{t('safe_payment')}
					</div>
				</div>
			</header>

			{shouldDisplaySteps && (
				<div className="content-wrapper">
					<div className="steps-container">
						<ol className="checkout-steps">
							<li className="checkout-step checkout-step-cart">
								<div className={`step-numb clickable`} onClick={() => handleNavigate(1)}>
									<div className="completed">
										<img src={step1} alt="user icon" />
									</div>
									<span className={`step-line step-line-1 ${step >= 2 && 'completed'}`}></span>
								</div>
								<p className="step-description">{t('checkoutheader_step1_title')}</p>
							</li>
							<li className="checkout-step checkout-step-cart">
								<div className={`step-numb ${step >= 2 ? 'clickable' : 'disabled'}`} onClick={() => handleNavigate(2)}>
									<div className="completed">
										<img src={step >= 2 ? step2 : step2Disabled} alt="user icon" />
									</div>
									<span className={`step-line step-line-2 ${step >= 3 && 'completed'}`}></span>
								</div>
								<p className="step-description">{t('checkoutheader_step2_title_mob')}</p>
							</li>
							<li className="checkout-step checkout-step-cart">
								<div className={`step-numb ${step == 3 ? 'clickable' : 'disabled'}`} onClick={() => handleNavigate(3)}>
									<div className="completed">
										<img src={step >= 3 ? step3 : step3Disabled} alt="user icon" />
									</div>
								</div>
								<p className="step-description">{t('checkoutheader_step3_title_mob')}</p>
							</li>
						</ol>
					</div>
				</div>
			)}
		</div>
	);
};

HeaderCheckout.propTypes = {
	step: PropTypes.number
};

export default HeaderCheckout;
