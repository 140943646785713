import { api_addCartItem, api_getCustomerCartItems } from './api_calls';
import UserSession from './user_session';

// ShopCart object defined as a closure wrapper
var ShopCart = (function () {
	var isEmpty = function () {
		var items = getItems();
		return items.length == 0;
	};

	var getItems = function () {
		try {
			var items = JSON.parse(localStorage.getItem('shopcart_items'));

			if (items == null) {
				items = [];
			}

			return items;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var clear = function () {
		try {
			localStorage.removeItem('shopcart_items');
		} catch (e) {
			console.log(e);
		}
	};

	var find = function (pid) {
		var items = getItems();

		for (let item of items) {
			if (item.pid == pid) {
				return item;
			}
		}

		return null;
	};

	var changeQuantity = function (token) {
		try {
			var items = getItems();
			var found = false;

			for (let item of items) {
				if (item.pid == token.pid && item.dimension == token.dimension) {
					item.quantity = token.quantity;
					found = true;
					break;
				}
			}

			if (found) {
				localStorage.setItem('shopcart_items', JSON.stringify(items));
			}

			return JSON.parse(localStorage.getItem('shopcart_items'));
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	var add = async function (token) {
		try {
			var items = getItems();
			var found = false;

			for (let item of items) {
				if (item.pid == token.pid && item.dimension == token.dimension) {
					item.quantity += token.quantity;
					found = true;
					break;
				}
			}

			if (!found) {
				items.push(token);
			}

			localStorage.setItem('shopcart_items', JSON.stringify(items));

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	var remove = function (token) {
		try {
			var items = getItems();
			let filtered = items.filter(item => item.pid !== token.pid || item.dimension !== token.dimension);

			if (filtered.length != items.length) {
				localStorage.setItem('shopcart_items', JSON.stringify(filtered));
				return JSON.parse(localStorage.getItem('shopcart_items'));
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	};

	var setDBCart = function (items) {
		try {
			localStorage.setItem('shopcart_items', JSON.stringify(items));

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	var getTotalQuantity = function () {
		try {
			var items = JSON.parse(localStorage.getItem('shopcart_items'));
			var totalQuantity = 0;

			if (items != null) {
				items.forEach(item => (totalQuantity += item.quantity));
			}

			return totalQuantity;
		} catch (e) {
			console.log(e);
			return totalQuantity;
		}
	};

	return {
		isEmpty,
		getItems,
		clear,
		find,
		add,
		remove,
		changeQuantity,
		getTotalQuantity,
		setDBCart
	};
})();

export default ShopCart;
