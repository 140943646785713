import useScroll from '~/components/hooks/useScroll';
import top from '~/images/arrow-right.svg';

const FixedButtons = ({ showScrollToTop = true }) => {
	const scrollPosition = useScroll();

	return (
		<div className="fixed-buttons-homepage">
			<div className="buttons-container">
				{showScrollToTop && (
					<button
						className="scrollToTop"
						style={{ visibility: scrollPosition > 600 ? 'visible' : 'hidden' }}
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
						<img src={top} alt="call" />
					</button>
				)}
			</div>
		</div>
	);
};

export default FixedButtons;
