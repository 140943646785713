import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '~/components/common/Button';
import { notifyError } from '~/components/common/ToastMessages';

const GiftcardStep4 = ({ currentStep, setCurrentStep, metaData, setMetaData }) => {
	const { t } = useTranslation();

	const nextStep = () => {
		if (metaData.recipientEmail.trim().length === 0 || metaData.confirmRecipientEmail.trim().length === 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (metaData.recipientEmail !== metaData.confirmRecipientEmail) {
			notifyError(t('recipient_email_confirm_mismatch'));
			return;
		}

		const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
		if (!emailRegex.test(metaData.recipientEmail)) {
			notifyError(t('validation_email'));
			return;
		}

		if (metaData.sendDate.trim().length === 0 || metaData.sendDate.trim().length > 16) {
			notifyError(t('giftcard_send_date_error'));
			return;
		}

		const selectedDate = new Date(metaData.sendDate);
		// selectedDate.setHours(0, 0, 0, 0); // Set time to midnight

		const today = new Date();
		// today.setHours(0, 0, 0, 0); // Set time to midnight

		if (selectedDate < today) {
			notifyError(t('giftcard_send_date_error_date'));
			return;
		}

		setCurrentStep(currentStep + 1);
	};

	return (
		<div className="giftcard-step">
			<div className="fourth-step">
				<h3>{t('add_recipient')}</h3>

				<div className="input-container">
					<input
						type="text"
						placeholder={t('recipient_email')}
						value={metaData.recipientEmail}
						onChange={e => setMetaData({ ...metaData, recipientEmail: e.target.value })}
					/>
					<input
						type="text"
						placeholder={t('recipient_email_confirm')}
						onChange={e => setMetaData({ ...metaData, confirmRecipientEmail: e.target.value })}
						value={metaData.confirmRecipientEmail}
						className={metaData.confirmRecipientEmail !== metaData.recipientEmail ? 'error' : ''}
					/>
				</div>

				<h3>{t('delivery_date')}</h3>

				<div className="input-container">
					<input
						type="datetime-local"
						name="date"
						placeholder="birthdate"
						value={metaData.sendDate}
						onChange={e => setMetaData({ ...metaData, sendDate: e.target.value })}
					/>
				</div>

				<div className="next-step-button">
					<Button color="secondary" actionOnClick={nextStep}>
						{t('next_step')}
					</Button>
				</div>
			</div>
		</div>
	);
};

GiftcardStep4.propTypes = {
	currentStep: PropTypes.number,
	setCurrentStep: PropTypes.func,
	metaData: PropTypes.object,
	setMetaData: PropTypes.func
};

export default GiftcardStep4;
