import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '~/components/common/Button';
import { notifyError } from '~/components/common/ToastMessages';

const GiftcardStep3 = ({ currentStep, setCurrentStep, metaData, setMetaData }) => {
	const { t } = useTranslation();
	const nextStep = () => {
		if (metaData.message.trim().length > 400) {
			notifyError(t('giftcard_msg_error'));
			return;
		}

		setCurrentStep(currentStep + 1);
	};

	return (
		<div className="giftcard-step">
			<div className="third-step">
				<h3> {t('add_msg')} </h3>

				<textarea
					onChange={e => setMetaData({ ...metaData, message: e.target.value })}
					className="giftcard-message"
					placeholder={t('add_msg_placeholder')}
					value={metaData.message}></textarea>

				<div className="next-step-button">
					<Button color="secondary" actionOnClick={nextStep}>
						{t('next_step')}
					</Button>
				</div>
			</div>
		</div>
	);
};

GiftcardStep3.propTypes = {
	currentStep: PropTypes.number,
	setCurrentStep: PropTypes.func,
	metaData: PropTypes.object,
	setMetaData: PropTypes.func
};

export default GiftcardStep3;
