import { call, put, takeEvery } from 'redux-saga/effects';

//import { useNavigate } from "react-router-dom";

// Register redux actions
import { registerCustomerErrorAction, registerCustomerSuccessAction } from '~/store/actions.js';

// API calls
import { api_facebookRegisterCustomer, api_googleRegisterCustomer, api_registerCustomer } from '~/backend/api_calls';

function* registerCustomer(action) {
	try {
		const response = yield call(api_registerCustomer, action.args);
		console.log('Register response: ' + response);

		yield put(registerCustomerSuccessAction('Registration completed'));
	} catch (error) {
		yield put(registerCustomerErrorAction(error));
	}
}

function* googleRegisterCustomer(action) {
	try {
		const response = yield call(api_googleRegisterCustomer, action.args);
		console.log('Register response: ' + response);

		yield put(registerCustomerSuccessAction('Google Registration completed'));
	} catch (error) {
		yield put(registerCustomerErrorAction(error));
	}
}

function* facebookRegisterCustomer(action) {
	try {
		const response = yield call(api_facebookRegisterCustomer, action.args);
		console.log('Register response: ' + response);

		yield put(registerCustomerSuccessAction('Facebook Registration completed'));
	} catch (error) {
		yield put(registerCustomerErrorAction(error));
	}
}

function* registerSaga() {
	yield takeEvery('register-customer', registerCustomer);
	yield takeEvery('google-register-customer', googleRegisterCustomer);
	yield takeEvery('facebook-register-customer', facebookRegisterCustomer);
}

export default registerSaga;
