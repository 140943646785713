const initialState = {
	url: '',
	loading: false
};

const filtersReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'flt-apply':
			state = { ...state, url: action, loading: true };
			break;
		case 'flt-reset':
			state = { ...state, url: '', loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default filtersReducer;
