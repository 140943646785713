import { api_getProfile, api_insertAddressByCustomer, api_updateAddressByCustomer } from '~/backend/api_calls';
import ShopCart from '~/backend/shop_cart';
import UserState from '~/backend/user_session';
import { validateCheckoutStep2Data } from './checkout_step_2';
import { setOrderlines } from './order_synopsis';

export const ensurePreviousStepsDataIsFilled = (order, shipToBillingAddress) => {
	if (!order.eml) {
		throw {
			message: 'User unauthenticated'
		};
	}

	if (order.rt != 0 && order.rt != 1) {
		throw {
			message: 'Select Receipt Type'
		};
	}

	if (order.tt == undefined) {
		throw {
			message: 'Select Tax Type'
		};
	}

	const billingInformation = {
		fnm: order.fnm,
		lnm: order.lnm,
		mob: order.mob,
		ctid: order.ctid,
		crid: order.crid,
		adr1: order.adr1,
		ct: order.ct,
		pc: order.pc,
		rt: order.rt,
		cnm: order.cnm,
		irs: order.irs,
		job: order.job,
		vat: order.vat
	};

	const shippingInformation = {
		dfnm: order.dfnm,
		dlnm: order.dlnm,
		dmob: order.dmob,
		dctid: order.dctid,
		dcrid: order.dcrid,
		dadr1: order.dadr1,
		dct: order.dct,
		dpc: order.dpc,
		ncm: order.ncm
	};

	try {
		validateCheckoutStep2Data(billingInformation, shippingInformation, order.rt, shipToBillingAddress);
	} catch (e) {
		console.log(e.payload);
		throw {
			payload: e.payload
		};
	}
};

export const ensureProductsExistInCart = () => {
	var items = ShopCart.getItems();

	if (items.length == 0) {
		throw {
			message: 'no_items_in_cart'
		};
	}
};

export const handleValidateAndSetOrder = async (
	privacyPolicyAccept,
	shipping,
	takeAwayStoreId,
	lockerId,
	selectedAddressCode,
	payment,
	order,
	loyaltyPoints,
	isGift,
	grantAmountTotal,
	giftcards,
	coupons,
	redeemPoints
) => {
	if (!privacyPolicyAccept) {
		throw 'validation_terms';
	}

	if (shipping == null || shipping.id == null || shipping.id == 0) {
		throw 'validation_shipping_method';
	}

	// If pickup from store then ensure user have selected store to pickup
	if (shipping.stw && (takeAwayStoreId == 0 || !takeAwayStoreId)) {
		throw 'validation_store';
	}

	// If BOXNOW then ensure user have selected locker
	if (shipping.ctd && (lockerId == 0 || !lockerId)) {
		throw 'validation_boxnow';
	}

	order.ptid = payment.id;

	if (payment == null || payment.id == null || payment.id == 0) {
		throw 'validation_payment_method';
	}

	// if user is authenticated assign the customer id to order
	if (UserState.isAuthenticated()) {
		const profile = await api_getProfile();
		order.cid = profile.id;

		if (order.adtl) {
			const address = {};
			if (order.adid) {
				address.id = order.adid;
				order.bscd = selectedAddressCode ?? ''; // Setting Billing Site Code
			}
			address.tl = order.adtl;
			address.fn = order.fnm;
			address.ln = order.lnm;
			address.crid = order.crid;
			address.ct = order.ct;
			address.adl = order.adr1;
			address.pc = order.pc;
			address.mob = order.mob;
			address.cnid = order.ctid;
			address.iv = true;
			address.cid = profile.id;

			try {
				if (address.id) {
					await api_updateAddressByCustomer(address);
				} else {
					await api_insertAddressByCustomer(address);
				}
			} catch (error) {
				console.error(error);
			}
		}
	}

	// if shipping is courier assign courier name to order
	if (!shipping.stw && !shipping.ctd) {
		order.scn = shipping.nm;
		order.tasid = null;
		order.lockerCode = null;
	}
	// if shipping is takeaway assign store id
	if (shipping.stw && !shipping.ctd) {
		order.scn = null;
		order.lockerCode = null;
		order.tasid = takeAwayStoreId;
	}
	// if shipping is boxnow assign locker id
	if (!shipping.stw && shipping.ctd) {
		order.scn = null;
		order.tasid = null;
		order.lockerCode = lockerId;
	}

	// In case they have been left from giftcard checkout
	order.gfnm = null;
	order.gffnm = null;
	order.gfmsg = null;
	order.gfremail = null;
	order.gfsdate = null;

	order.stid = shipping.id;
	order.lines = setOrderlines();
	order.couponscodes = coupons;
	order.rdpt = redeemPoints;

	return order;
};

export const handleOrderCompletedResponse = orderResponse => {
	let msg = '';
	let navUrl = '';
	let replace = false;

	// Redirect to payment page
	if (orderResponse.opru != null && orderResponse.opru.length > 0) {
		msg = "Please don't close this window, you are being transferred to a secure payment page";
		navUrl = orderResponse.opru;
		replace = true;

		return {
			msg,
			navUrl,
			replace
		};
	}

	if (orderResponse.uid && orderResponse.ops == 0) {
		msg = 'Success';
		navUrl = '/checkout-success/' + orderResponse.uid;
		replace = false;

		return {
			msg,
			navUrl,
			replace
		};
	}

	if (orderResponse.ops < 0) {
		throw {
			msg: 'Payment response: ' + orderResponse.opm + '\nPlease contact us'
		};
	}
};

export const handleGetAvailableShippingOptions = (shippingOptions, shippingCountryId) => {
	// Exclude certain shipping options
	let handledShippingOptions = shippingOptions.filter(item => !excludedShippingOptionsCodes.includes(item.cd));

	// Get available shipping options by country
	handledShippingOptions = handledShippingOptions.filter(item => {
		const country = availableShippingOptionsByCountryId.find(item => item.countryId === shippingCountryId);

		if (country) {
			const availableShippingOptionsCodes = country.availableShippingOptionsCodes;
			return availableShippingOptionsCodes?.includes(item.cd);
		}

		// if shippingCountry id is not included in the availableShippingOptionsByCountryId array get default shipping options
		const availableShippingOptionsCodes = availableShippingOptionsByCountryId.find(
			item => item.countryId === -1
		).availableShippingOptionsCodes;

		return availableShippingOptionsCodes?.includes(item.cd);
	});

	return handledShippingOptions;
};

export const handleGetAvailablePaymentOptions = (paymentOptions, shippingCountryId, shippingCode) => {
	let handledPaymentOptions = paymentOptions;

	// Exclude certain Payment options
	handledPaymentOptions = handledPaymentOptions.filter(item => !excludedPaymentOptionsCodes.includes(item.cd));

	// Get Available Payment Options By Country
	handledPaymentOptions = handledPaymentOptions.filter(item => {
		const country = availablePaymentOptionsByCountryId.find(item => item.countryId === shippingCountryId);

		if (country) {
			const availablePaymentOptionsCodes = country.availablePaymentOptionsCodes;
			return availablePaymentOptionsCodes?.includes(item.cd);
		}

		// if shippingCountry id is not included in the availableShippingOptionsByCountryId array get default shipping options
		const availablePaymentOptionsCodes = availablePaymentOptionsByCountryId.find(
			item => item.countryId === -1
		).availablePaymentOptionsCodes;

		return availablePaymentOptionsCodes;
	});

	// Get available payment options by shipping code
	handledPaymentOptions = handledPaymentOptions.filter(item => {
		const availablePaymentOptionsCodes =
			availablePaymentOptionsByShipmentCode.find(item => item.shipmentOptionCode === shippingCode)?.availablePaymentOptionsCodes || [];

		return availablePaymentOptionsCodes.includes(item.cd);
	});

	return handledPaymentOptions;
};

const excludedShippingOptionsCodes = ['GIFTCARD'];

const excludedPaymentOptionsCodes = [];

const availableShippingOptionsByCountryId = [
	{
		countryId: 1, // Greece
		availableShippingOptionsCodes: ['PICKUP', 'ACS', 'BOX-NOW']
	},
	{
		countryId: -1, // EVERYTHING ELSE / ABROAD
		availableShippingOptionsCodes: ['PICKUP', 'DHL', 'BOX-NOW']
	}
];

const availablePaymentOptionsByCountryId = [
	{
		countryId: 1, // Greece
		availablePaymentOptionsCodes: ['CASH-ON-DELIVERY', 'CREDIT-CARD', 'KLARNA', 'PAYPAL', 'BOXNOW-CASH-ON-DELIVERY']
	},
	{
		countryId: -1, // EVERYTHING ELSE / ABROAD
		availablePaymentOptionsCodes: ['CREDIT-CARD', 'KLARNA', 'PAYPAL', 'BOXNOW-CASH-ON-DELIVERY']
	}
];

const availablePaymentOptionsByShipmentCode = [
	{
		shipmentOptionCode: 'PICKUP',
		availablePaymentOptionsCodes: ['CREDIT-CARD', 'KLARNA', 'PAYPAL']
	},
	{
		shipmentOptionCode: 'ACS',
		availablePaymentOptionsCodes: ['CASH-ON-DELIVERY', 'CREDIT-CARD', 'KLARNA', 'PAYPAL']
	},
	{
		shipmentOptionCode: 'DHL',
		availablePaymentOptionsCodes: ['CREDIT-CARD', 'KLARNA', 'PAYPAL']
	},
	{
		shipmentOptionCode: 'BOX-NOW',
		availablePaymentOptionsCodes: ['BOXNOW-CASH-ON-DELIVERY', 'CREDIT-CARD', 'KLARNA', 'PAYPAL']
	}
];
