const initialState = {
	error: '',
	message: '',
	loading: false
};

const registerReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'register-customer':
			state = { ...state, error: '', loading: true };
			break;
		case 'register-customer-success':
			state = { ...state, message: action.message, loading: false };
			break;
		case 'register-customer-error':
			state = { ...state, error: action.error, loading: false };
			break;
		case 'register-clear-messages':
			state = { ...state, message: '', error: '', loading: false };
			break;
		case 'google-register-customer':
			state = { ...state, error: '', loading: true };
			break;
		case 'facebook-register-customer':
			state = { ...state, error: '', loading: true };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default registerReducer;
