import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatAmountValue } from '~/common/helper';
import CartItem from '../cart/CartItem.js';

import ShopCart from '~/backend/shop_cart';

import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { api_composeCartTotals, api_getShippingChargeByCountry, api_updateCustomerDBCart } from '~/backend/api_calls';
import UserSession from '~/backend/user_session';
import { simplerCartWidget } from '~/common/simpler.js';
import shipping from '~/images/shipping.svg';
import { headerCartQuantityAction, openCartWidgetAction } from '~/store/actions.js';
import { notifyError } from './ToastMessages.js';

const CartWidget = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [cartItems, setCartItems] = useState(ShopCart.getItems());
	const [orderTotals, setOrderTotals] = useState({});
	const [orderMinForDeliveryFee, setOrderMinForDeliveryFee] = useState(null);
	const firstUpdate = useRef(true);

	const closeCart = () => {
		document.body.classList.remove('cart-open');
		dispatch(openCartWidgetAction(false));
	};

	const goCheckout = e => {
		const cartItemErrors = document.getElementsByClassName('cart-item-error');

		if (cartItemErrors.length > 0) {
			notifyError(t('cart_fix_errors_prompt'));
		} else {
			navigate('/checkout-step-1');
			closeCart();
		}
	};
	const deliveryFee = totalAmount => {
		if (!orderMinForDeliveryFee) return null;

		if (totalAmount === undefined || totalAmount >= orderMinForDeliveryFee) {
			return null;
		} else {
			let dif = orderMinForDeliveryFee - totalAmount;
			let b = t('delivery_fee').replace('{price}', formatAmountValue(dif));
			return (
				<>
					<li className="flex-col">
						<img src={shipping} alt="shipping" />
						{b}
					</li>
				</>
			);
		}
	};

	useEffect(() => {
		if (cartItems.length > 0) {
			api_composeCartTotals(cartItems).then(json => {
				setOrderTotals(json);
			});
		}
		dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));

		if (!firstUpdate.current && UserSession.isAuthenticated()) {
			api_updateCustomerDBCart({ items: cartItems });
		}

		//simpler
		const simplerElement = document.getElementById('simpler-minicart');
		if (simplerElement) {
			for (const child of simplerElement.children) {
				child.remove();
			}
			simplerCartWidget(cartItems);
		}
		firstUpdate.current = false;
	}, [cartItems]);

	useEffect(() => {
		api_getShippingChargeByCountry(1).then(json => {
			setOrderMinForDeliveryFee(json.prf);
		});
	}, []);

	if (cartItems.length == 0) {
		return (
			<div id="cart-widget" className="flex-col">
				<div className="transparent-side" onClick={() => closeCart()}></div>
				<div className="cart-widget">
					<div className="empty-content">{t('no_items_in_cart')}</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div id="cart-widget" className="flex-col">
				<div className="transparent-side" onClick={() => closeCart()}></div>
				<div className="cart-widget">
					<div className="head-account">
						<h2>
							{t('your_cart')} ({cartItems.length})
						</h2>
					</div>
					<div className="cart-items grid-col">
						{cartItems.map((item, index) => (
							<CartItem cartItem={item} key={item.pid + '_' + item.dimension} setCartItems={setCartItems} />
						))}
					</div>
					<div className="sticky-cart-total">
						<div className="total flex-col">
							<div className="name">{t('order_total')}</div>
							<div className="amount">{formatAmountValue(orderTotals.gamt)}</div>
						</div>
						<div className="buttons-cart flex-col">
							<button
								type="button"
								className="submit white-button"
								onClick={() => {
									closeCart();
									navigate('/cart');
								}}>
								{t('go_to_cart')}
							</button>
							<button type="button" className="submit arrow-button black-arrow-button" onClick={e => goCheckout(e)}>
								{t('go_to_checkout')}
							</button>
						</div>
						<div className="simpler-btn" id="simpler-minicart"></div>
						<ul className="cart-ship-info grid-col">{deliveryFee(orderTotals.amt)}</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default CartWidget;
