export const registerCustomerAction = args => {
	return {
		type: 'register-customer',
		args
	};
};

export const registerCustomerSuccessAction = message => {
	return {
		type: 'register-customer-success',
		message
	};
};

export const registerCustomerErrorAction = error => {
	return {
		type: 'register-customer-error',
		error
	};
};

export const clearRegisterMessagesAction = () => {
	return {
		type: 'register-clear-messages'
	};
};

export const googleRegisterCustomerAction = args => {
	return {
		type: 'google-register-customer',
		args
	};
};

export const facebookRegisterCustomerAction = args => {
	return {
		type: 'facebook-register-customer',
		args
	};
};
