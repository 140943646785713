// import './App.css';
import PropTypes from 'prop-types';
import { Suspense, lazy } from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

/*import TagManager from 'react-gtm-module';*/
import ReactPixel from 'react-facebook-pixel';

import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute } from './routes';

import HistoryMonitor from '~/components/common/HistoryMonitor';
import LanguageMonitor from '~/components/common/LanguageMonitor';
import LoadingSpinner from '~/components/common/LoadingSpinner';
import PageHeaderMeta from '~/components/common/PageHeaderMeta';
import ScrollToTop from '~/components/common/ScrollToTop';
import UpdateCartDB from '~/components/common/UpdateCartDB';
import UpdateCookieServices from '~/components/common/UpdateCookieServices';
import ImpersonationHeader from '~/components/layout/ImpersonationHeader';
import LayoutClassic from '~/components/layout/LayoutClassic';
import AddToWishlist from '~/components/wishlist/AddToWishlist';
import Giftcard from '~/pages/Giftcards/Giftcard';

// Lazy load page components
const Home = lazy(() => import('~/pages/Home'));
const Login = lazy(() => import('~/pages/Customer/Login'));
const Logout = lazy(() => import('~/pages/Customer/Logout'));
const Profile = lazy(() => import('~/pages/Customer/Profile'));
const Account = lazy(() => import('~/pages/Customer/Account'));
const Coupons = lazy(() => import('~/pages/Customer/Coupons'));
const Addresses = lazy(() => import('~/pages/Customer/Addresses'));
const AddressEdit = lazy(() => import('~/pages/Customer/AddressEdit'));
const AddressAdd = lazy(() => import('~/pages/Customer/AddressAdd'));
const OrderHistory = lazy(() => import('~/pages/Customer/OrderHistory'));
const Order = lazy(() => import('~/pages/Customer/Order'));
const OrderReturn = lazy(() => import('~/pages/Customer/OrderReturn'));
const FollowOrder = lazy(() => import('~/pages/Customer/FollowOrder'));
const Register = lazy(() => import('~/pages/Customer/Register'));
const CheckoutStep1 = lazy(() => import('~/pages/CheckoutStep1'));
const CheckoutStep2 = lazy(() => import('~/pages/CheckoutStep2'));
const CheckoutStep3 = lazy(() => import('~/pages/CheckoutStep3'));
const CheckoutSuccess = lazy(() => import('~/pages/CheckoutSuccess'));
const CheckoutFailure = lazy(() => import('~/pages/CheckoutFailure'));
const RegistrationCheckEmail = lazy(() => import('~/pages/Customer/RegistrationCheckEmail'));
const NotFound = lazy(() => import('~/pages/NotFound'));
const Product = lazy(() => import('~/pages/Product'));
const ContentPage = lazy(() => import('~/pages/ContentPage'));
const CategoryList = lazy(() => import('~/pages/Category/CategoryList'));
const Wishlists = lazy(() => import('~/pages/Customer/Wishlists'));
const Wishlist = lazy(() => import('~/pages/Customer/Wishlist'));
const GuestWishlist = lazy(() => import('~/pages/Customer/GuestWishlist'));
const CustomerNavigationPage = lazy(() => import('~/pages/Customer/CustomerNavigationPage'));
const Cart = lazy(() => import('~/pages/Cart'));
const Contact = lazy(() => import('~/pages/Contact'));
const NewsList = lazy(() => import('~/pages/News/NewsList'));
const NewsCategory = lazy(() => import('~/pages/News/NewsCategory'));
const NewsEntry = lazy(() => import('~/pages/News/NewsEntry'));
const StoreFinder = lazy(() => import('~/pages/StoreFinder'));
const ValidateNewsletterSubscription = lazy(() => import('~/pages/ValidateNewsletterSubscription'));
const ValidateCustomerRegistration = lazy(() => import('~/pages/ValidateCustomerRegistration'));
const ResetPasswordRequest = lazy(() => import('~/pages/Customer/ResetPasswordRequest'));
const ResetPassword = lazy(() => import('~/pages/Customer/ResetPassword'));
const Search = lazy(() => import('~/pages/Search'));
const Sitemap = lazy(() => import('~/pages/Xml/Sitemap'));
const Impersonation = lazy(() => import('~/pages/Customer/Impersonation'));
// const Giftcard = lazy(() => import('~/pages/Giftcards/Giftcard'));

// Viva Payments
const VivaSuccess = lazy(() => import('~/pages/VivaPayments/VivaSuccess'));
const VivaFailure = lazy(() => import('~/pages/VivaPayments/VivaFailure'));

// PayPal
const PayPalSuccess = lazy(() => import('~/pages/PayPal/PayPalSuccess'));
const PayPalCancel = lazy(() => import('~/pages/PayPal/PayPalCancel'));

// Alpha Cardlink
const AlphaCardlinkRequestForm = lazy(() => import('~/pages/AlphaBank/AlphaCardlinkRequestForm'));
const AlphaCardlinkSuccess = lazy(() => import('~/pages/AlphaBank/AlphaCardlinkSuccess'));
const AlphaCardlinkCancel = lazy(() => import('~/pages/AlphaBank/AlphaCardlinkCancel'));

// Winbank
const WinbankRequestForm = lazy(() => import('~/pages/Winbank/WinbankRequestForm'));
const WinbankSuccess = lazy(() => import('~/pages/Winbank/WinbankSuccess'));
const WinbankCancel = lazy(() => import('~/pages/Winbank/WinbankCancel'));

function App() {
	//const tagManagerArgs = {
	//    gtmId: process.env.REACT_APP_GTAG_4_ID
	//}

	//TagManager.initialize(tagManagerArgs);

	const options = {
		autoConfig: true, // set pixel's autoConfig
		debug: false // disable logs
	};

	ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);

	UpdateCartDB();
	UpdateCookieServices();

	const showAddToWishlist = useSelector(state => state.layoutReducer.showAddToWishlist);
	const wishlistProductId = useSelector(state => state.layoutReducer.wishlistProductId);

	const createAppRoutes = () => {
		return (
			<>
				<Route index element={<Home />} />

				<Route path="pages/:eurl" element={<ContentPage />} />

				<Route path="products/:eurl" element={<Product />} />

				<Route path="categories/:eurl" element={<CategoryList />} />

				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />

				<Route path="cart" element={<Cart />} />

				<Route path="contact" element={<Contact />} />

				<Route path="blog" element={<NewsList />} />
				<Route path="blog/:eurl" element={<NewsCategory />} />
				<Route path="blogPost/:eurl" element={<NewsEntry />} />

				<Route path="checkout-step-1" element={<CheckoutStep1 />} />

				<Route path="giftcard" element={<Giftcard />} />

				{/*<Route element={<OrderWizardRoute/>}>*/}
				<Route path="checkout-step-2" element={<CheckoutStep2 />} />
				<Route path="checkout-step-3" element={<CheckoutStep3 />} />
				<Route path="checkout-success/:uid" element={<CheckoutSuccess />} />
				<Route path="checkout-failure" element={<CheckoutFailure />} />
				{/*</Route>*/}

				<Route path="register" element={<Register />} />
				<Route path="registrationcheckemail" element={<RegistrationCheckEmail />} />

				<Route path="wishlist/:id" element={<Wishlist />} />
				<Route path="wishlist" element={<GuestWishlist />} />

				<Route path="storefinder" element={<StoreFinder />} />

				<Route path="validatenewslettersubscription/:guid" element={<ValidateNewsletterSubscription />} />
				<Route path="validateregistration/:guid" element={<ValidateCustomerRegistration />} />

				<Route path="resetpasswordrequest" element={<ResetPasswordRequest />} />
				<Route path="resetpassword/:guid" element={<ResetPassword />} />

				<Route path="impersonate/:guid" element={<Impersonation />} />

				<Route path="search" element={<Search />} />

				<Route path="payment/viva/success" element={<VivaSuccess />} />
				<Route path="payment/viva/failure" element={<VivaFailure />} />

				<Route path="payment/paypal/success" element={<PayPalSuccess />} />
				<Route path="payment/paypal/cancel" element={<PayPalCancel />} />

				<Route path="payment/alpha_request/:id" element={<AlphaCardlinkRequestForm />} />
				<Route path="payment/alpha/success/:uid" element={<AlphaCardlinkSuccess />} />
				<Route path="payment/alpha/cancel" element={<AlphaCardlinkCancel />} />

				<Route path="payment/winbank/:id" element={<WinbankRequestForm />} />
				<Route path="payment/winbank/success/:uid" element={<WinbankSuccess />} />
				<Route path="payment/winbank/cancel" element={<WinbankCancel />} />

				<Route element={<ProtectedRoute />}>
					<Route path="profile" element={<Profile />} />
					<Route path="coupons" element={<Coupons />} />
					<Route path="addresses" element={<Addresses />} />
					<Route path="address/edit/:id" element={<AddressEdit />} />
					<Route path="address/add" element={<AddressAdd />} />
					<Route path="orders" element={<OrderHistory />} />
					<Route path="order/:uid" element={<Order />} />
					<Route path="order-return/:uid" element={<OrderReturn />} />
					<Route path="account" element={<Account />} />
					<Route path="wishlists" element={<Wishlists />} />
					<Route path="followorder" element={<FollowOrder />} />
					<Route path="followorder/:uid" element={<FollowOrder />} />
					<Route path="navigation" element={<CustomerNavigationPage />} />
				</Route>

				<Route path="notfound" element={<NotFound />} />
				<Route path="*" element={<NotFound />} />
			</>
		);
	};

	return (
		<div className="App">
			<Router>
				<HistoryMonitor />
				<LanguageMonitor />
				<PageHeaderMeta />
				<ScrollToTop />
				<ImpersonationHeader />
				<Suspense fallback={<LoadingSpinner isLoading={true} />}>
					<Routes>
						<Route
							path="/"
							element={
								<LayoutClassic>
									<Outlet />
								</LayoutClassic>
							}>
							{createAppRoutes()}
						</Route>

						<Route
							path="/el"
							element={
								<LayoutClassic>
									<Outlet />
								</LayoutClassic>
							}>
							{createAppRoutes()}
						</Route>

						<Route
							path="/en"
							element={
								<LayoutClassic>
									<Outlet />
								</LayoutClassic>
							}>
							{createAppRoutes()}
						</Route>

						<Route path="/sitemap.xml" element={<Sitemap />} />
					</Routes>
				</Suspense>
			</Router>

			<ToastContainer className="toast-content" />
			{showAddToWishlist == true ? <AddToWishlist productId={wishlistProductId} /> : null}
			{/* <CookiesBanner /> */}
		</div>
	);
}

App.propTypes = {
	showAddToWishlist: PropTypes.bool,
	wishlistProductId: PropTypes.number
};

export default App;
