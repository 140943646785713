import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import googleSVG from '~/images/google.svg';
import { googleLoginAction } from '~/store/actions.js';

const GoogleLoginButton = () => {
	const { t } = useTranslation();
	const [tokenResponse, setTokenResponse] = useState(null);
	const [credentials, setCredentials] = useState(null);
	const loginSuccessMessage = useSelector(state => state.authReducer.message);
	const dispatch = useDispatch();

	const loginOrRegister = useGoogleLogin({
		onSuccess: response => setTokenResponse(response)
	});

	//Use TokenResponse to get user's credentials from the googleapi site
	useEffect(() => {
		if (tokenResponse === null) return;

		fetch('https://www.googleapis.com/oauth2/v1/userinfo?access_token=' + tokenResponse.access_token)
			.then(response => response.json())
			.then(data => setCredentials(data));
	}, [tokenResponse]);

	//Use user's credentials to actually login
	useEffect(() => {
		if (credentials === null) return;

		let args = {
			eml: credentials.email,
			fn: credentials.given_name,
			ln: credentials.family_name,
			google_id: credentials.id,
			psw: 'notNeeded'
		};

		dispatch(googleLoginAction(args));
	}, [credentials]);

	return (
		<button className="flex-col" onClick={() => loginOrRegister()}>
			<img src={googleSVG} />
			Google
		</button>
	);
};

export default GoogleLoginButton;
