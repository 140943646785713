import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SearchBar = ({ onSearchSubmit, clearResults }) => {
	SearchBar.propTypes = {
		onSearchSubmit: PropTypes.any,
		clearResults: PropTypes.any
	};
	const { t, i18n } = useTranslation();
	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 1000);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	// submit a new search
	useEffect(() => {
		if (term !== '' && term.length > 2) {
			onSearchSubmit(term);
		} else {
			clearResults();
		}
	}, [term]);

	return (
		<div className="searchbar">
			<input
				className="searchbar-input"
				type="text"
				placeholder={t('search_modal_title')}
				onChange={e => setDebouncedTerm(e.target.value)}
				value={debouncedTerm}
			/>
		</div>
	);
};

export default SearchBar;
