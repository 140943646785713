import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageHeaderMeta from '~/components/common/PageHeaderMeta';

import LoginSection from '~/components/login/LoginSection';
import RegisterSection from '~/components/login/RegisterSection';
import Register from '~/pages/Customer/Register.js';

import LoadingSpinner from '~/components/common/LoadingSpinner';

import ShopCart from '~/backend/shop_cart';

import UserSession from '~/backend/user_session';
import SwitchAccount from '~/components/checkout/CheckoutStep1/SwitchAccount';

const CheckoutStep1 = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const loading = useSelector(state => state.authReducer.loading);
	const [forceShowLogin, setForceShowLogin] = useState(!UserSession.isAuthenticated());
	const [selectedTab, setSelectedTab] = useState(0);
	const [isShownRegister, setIsShownRegister] = useState(false);

	// We do not want the user to go at checkout with no products
	useEffect(() => {
		if (ShopCart.getItems().length === 0) {
			navigate('/');
			return;
		}

		// const checkoutStep = 1;
		// sendCheckoutEvent(checkoutStep, product);
	}, []);

	return (
		<>
			<PageHeaderMeta page_title={'Checkout Step 1'} />

			<div className="checkout-step1-page">
				<div className={'login-modal'}>
					<main>
						{!forceShowLogin ? (
							<SwitchAccount setForceShowLogin={setForceShowLogin} />
						) : (
							<>
								<ul className="flex-col">
									<li>
										<button
											id="register"
											className={`tab-log ${selectedTab === 0 ? 'active-log-tab' : ''}`}
											type="button"
											onClick={() => setSelectedTab(0)}>
											{t('register')}
										</button>
									</li>
									<li>
										<button
											id="login"
											className={`tab-log ${selectedTab === 1 ? 'active-log-tab' : ''}`}
											type="button"
											onClick={() => {
												setSelectedTab(1);
												setIsShownRegister(false);
											}}>
											{t('sign_in_title')}
										</button>
									</li>
								</ul>

								{selectedTab === 1 && <LoginSection pathToRedirect="/checkout-step-2" />}
								{!isShownRegister && selectedTab === 0 && <RegisterSection setIsShownRegister={setIsShownRegister} />}
								{isShownRegister && <Register />}
							</>
						)}
					</main>
				</div>
			</div>
			<LoadingSpinner isLoading={loading} />
		</>
	);
};

export default CheckoutStep1;
