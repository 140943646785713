import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { api_getContentPageByCode, api_getMainMenu } from '~/backend/api_calls';
import UserState from '~/backend/user_session';
import ContentPageMenuLinks from '~/components/common/ContentPageMenuLinks';

//const setLangChangeListener = () => {
//    $('#lang-switch').click(function () {
//        changeLanguage();
//    });
//};

//const changeLanguage = () => {
//    const langName = $('#lang-name');
//    const langFlag = $('#lang-flag');
//    var lang;

//    if (langName.text().toLowerCase() == 'greek') {
//        langName.text('English');
//        langFlag.removeClass('lang-gr');
//        langFlag.addClass('lang-en');
//        lang = "en";
//    } else {
//        langName.text('Greek');
//        langFlag.removeClass('lang-en');
//        langFlag.addClass('lang-gr');
//        lang = "el";
//    }

//    // set language as i18n
//    i18n.changeLanguage(lang);
//    localStorage.setItem("I18N_LANGUAGE", lang);

//    // refresh page to get content
//    location.reload();
//};

const arrayTo_N_Tree = (arr = []) => {
	let map = {},
		node,
		res = [],
		i;
	for (i = 0; i < arr.length; i += 1) {
		map[arr[i].id] = i;
		arr[i].children = [];
		if (!arr[i].pid) {
			arr[i].parentid = 0;
		} else {
			arr[i].parentid = arr[i].pid;
		}
	}
	for (i = 0; i < arr.length; i += 1) {
		node = arr[i];
		if (node.parentid !== 0) {
			arr[map[node.parentid]]?.children?.push(node);
		} else {
			res.push(node);
		}
	}

	res.forEach(element => {
		PreorderMenu(element, 0);
	});

	return res;
};

const PreorderMenu = (root, level, ans = []) => {
	if (!root) return ans;

	root.level = level;

	for (let child of root.children) {
		level++;
		PreorderMenu(child, level, ans);
		level--;
	}
};

const MenuItem = ({ menuItem, menuItems, index }) => {
	const { t, i18n } = useTranslation();

	const [activeNode, setActiveNode] = useState([]);

	const handleToggle = node => {
		activeNode.includes(node) ? setActiveNode(current => current.filter(n => n !== node)) : setActiveNode([...activeNode, node]);
	};

	const renderTree = nodes => {
		return nodes.map((node, index) => (
			<ul className="myUL" key={index}>
				<div
					className={node.children.length > 0 ? (activeNode.includes(node) ? 'caret caret-down' : 'caret') : 'no-children'}
					onClick={() => handleToggle(node)}>
					{node.children && node.children.length > 0 ? (
						<a style={{ marginLeft: (node.level - 1) * 40 }}>{node.tl}</a>
					) : (
						<a href={node.nu} style={{ marginLeft: (node.level - 1) * 40 }}>
							{node.tl}
						</a>
					)}
				</div>

				{node.children && <li className={activeNode.includes(node) ? 'nested active' : 'nested'}>{renderTree(node.children)}</li>}
			</ul>
		));
	};

	if (menuItems.length === 0 || menuItem === undefined) return null;

	let currentclasses = 'menu-item';

	if (index == 0) {
		currentclasses += ' active ';
	}

	const activateCategory = event => {
		var elements = window.document.querySelectorAll('.menu-item');
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList.remove('active');
		}
		event.currentTarget.classList.add('active');
	};

	return (
		<div className={currentclasses} id={menuItem.tl} onClick={activateCategory} key={menuItem.id}>
			<a className="main-item">{menuItem.tl}</a>

			<div className="sub-menu">
				<div>
					{/* {menuItems.filter(x => x.pid === menuItem.id).map(item => <a href={item.nu} key={item.id}>{item.tl}</a>)} */}
					{renderTree(arrayTo_N_Tree(menuItems).filter(x => x.id === menuItem.id)[0].children)}
					{/* {menu_N_Tree.filter(x => x.pid === menuItem.id).preorderMenu(item => <a href={item.nu} key={item.id}>{item.tl}</a>)} */}
					{/* {menu_N_Tree.filter(x => x.pid === menuItem.id).preorderMenu(item => <a href={item.nu} key={item.id}>{item.tl}</a>)} */}
				</div>
				<InfoItem />
			</div>
		</div>
	);
};

const InfoItem = () => {
	const { t, i18n } = useTranslation();

	const infoToggle = () => {
		var elements = window.document.querySelectorAll('.info-sub-menu, .item-title');
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList.toggle('active');
		}
	};

	return (
		<div className="info-item">
			<div className="item-title" onClick={infoToggle}>
				INFO
				<div className="icon">
					<svg className="open-info" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
						<g transform="translate(1 1)">
							<g transform="translate(8.5) rotate(45)">
								<line x2="12.021" y2="12.021" transform="translate(0 0)" fill="none" stroke="#000" strokeWidth="2" />
								<line x1="12.021" y2="12.021" transform="translate(0 0)" fill="none" stroke="#000" strokeWidth="2" />
							</g>
						</g>
					</svg>
					<svg className="close-info" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
						<g id="plus" transform="translate(1 1)">
							<g id="plus-2" data-name="plus" transform="translate(8.5 0) rotate(45)">
								<line
									id="Line_489"
									data-name="Line 489"
									x1="12.021"
									y2="12.021"
									transform="translate(0 0)"
									fill="none"
									stroke="#000"
									strokeWidth="2"
								/>
							</g>
						</g>
					</svg>
				</div>
			</div>
			<div className="info-sub-menu">
				<Link to="/blog">Blog</Link>
				<ContentPageMenuLinks show_main_menu={true} />
			</div>
		</div>
	);
};

MenuItem.propTypes = {
	menuItem: PropTypes.object,
	menuItems: PropTypes.array,
	index: PropTypes.number
};

const MenuModal = () => {
	const [menuContent, setMenuContent] = useState({});
	const [topCategories, setTopCategories] = useState({});
	const [menuItems, setMenuItems] = useState([]);

	const { t, i18n } = useTranslation();

	useEffect(() => {
		/*setLangChangeListener();*/

		api_getContentPageByCode('modal_menu')
			.then(json => {
				// Apply login state
				if (json.bd != null) {
					if (!UserState.isAuthenticated()) {
						json.bd = json.bd.replace('{Sign-In-Link}', "<a href='/login'>Sign in</a>");
					} else {
						json.bd = json.bd.replace('{Sign-In-Link}', "<a href='/profile'>Profile</a>");
					}
				}

				setMenuContent(json);

				api_getMainMenu()
					.then(json => {
						setMenuItems(json.items);
					})
					.catch(error => {
						console.error(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	return (
		<div id="menu-modal">
			<nav>
				<div className="main-menu">
					{menuItems
						.filter(x => x.pid === undefined || x.parentid === 0)
						.map((item, index) => (
							<MenuItem key={item.id} menuItem={item} index={index} menuItems={menuItems} />
						))}
				</div>
			</nav>
		</div>
	);
};

export default MenuModal;
