import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { api_getGiftcardProducts } from '~/backend/api_calls';
import { focusSelect, unFocusSelect } from '~/common/selects.js';
import Button from '~/components/common/Button';
import { GiftcardOptionsLoader } from '~/components/common/SkeletonLoaders';
import { notifyError } from '~/components/common/ToastMessages';

const GiftcardStep2 = ({ currentStep, setCurrentStep, metaData, setMetaData }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [giftcardOptions, setGiftcardOptions] = useState([]);

	const quantityOptions = [
		{ label: 1, value: 1 },
		{ label: 2, value: 2 },
		{ label: 3, value: 3 },
		{ label: 4, value: 4 },
		{ label: 5, value: 5 },
		{ label: 6, value: 6 },
		{ label: 7, value: 7 },
		{ label: 8, value: 8 },
		{ label: 9, value: 9 },
		{ label: 10, value: 10 }
	];

	useEffect(() => {
		setLoading(true);
		api_getGiftcardProducts()
			.then(json => setGiftcardOptions(json.items))
			.catch(err => console.error(err))
			.finally(() => setLoading(false));
	}, []);

	const nextStep = () => {
		if (!metaData.giftcard.id) {
			notifyError(t('giftcard_amount_required'));
			return;
		}

		if (metaData.qty === 0) return;

		setCurrentStep(currentStep + 1);
	};

	return (
		<div className="giftcard-step">
			<div className="second-step">
				{loading && <GiftcardOptionsLoader />}
				{!loading && giftcardOptions.length !== 0 && (
					<>
						<h3>{t('giftcard_amount')}</h3>

						<div className="giftcard-options-container">
							<div className="giftcard-options">
								{giftcardOptions.map((option, index) => (
									<div
										key={index}
										className={`option ${metaData.giftcard.id === option.id ? 'active' : ''}`}
										onClick={() => setMetaData({ ...metaData, giftcard: option })}>
										<h4>{option.prc}</h4>
									</div>
								))}
							</div>
						</div>

						<div className="giftcard-quantity">
							<h4>{t('quantity')}</h4>
							<Select
								id="quantity"
								name="quantity"
								options={quantityOptions}
								onChange={e => setMetaData({ ...metaData, qty: e.value })}
								value={quantityOptions.find(option => option.value === metaData.qty) ?? 'Select'}
								required
								onMenuClose={() => {
									unFocusSelect('quantity');
								}}
								onMenuOpen={() => {
									focusSelect('quantity');
								}}
								// styles={dropDownSelectStylesQuantityGiftcard}
							/>
						</div>

						<div className="next-step-button">
							<Button color="secondary" actionOnClick={nextStep}>
								{t('next_step')}
							</Button>
						</div>
					</>
				)}
				{!loading && giftcardOptions.length === 0 && <h4>Δεν βρέθηκαν giftcard</h4>}
			</div>
		</div>
	);
};

GiftcardStep2.propTypes = {
	currentStep: PropTypes.number,
	setCurrentStep: PropTypes.func,
	metaData: PropTypes.object,
	setMetaData: PropTypes.func
};

export default GiftcardStep2;
