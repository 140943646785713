import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { api_getCitiesByCountryRegion, api_getCountries, api_getCountryRegions, api_getPostalCodes } from '~/backend/api_calls';
import UserSession from '~/backend/user_session';
import { sortLabels } from '~/common/helper';
import { dropDownSelectStylesStatePC, focusSelect, unFocusSelect } from '~/common/selects.js';

const BillingInputs = ({ metaData, setMetaData }) => {
	const { t } = useTranslation();
	const inputErrors = useSelector(state => state.checkoutReducer.inputErrors);

	const [countries, setCountries] = useState([]);
	const [regions, setRegions] = useState([]);
	const [cities, setCities] = useState([]);
	const [postalCodes, setPostalCodes] = useState([]);

	useEffect(() => {
		api_getCountries().then(json => {
			const countriesJson = json.items.map(country => {
				return { label: country.nm, value: country.id };
			});
			setCountries(countriesJson);
		});

		const countryId = metaData.billingCountryId || 0;
		api_getCountryRegions(countryId).then(json => {
			const regionsJson = json.items.map(region => {
				return { label: region.nm, value: region.id };
			});
			setRegions(regionsJson);
		});

		const countryRegionId = metaData.billingRegionId || 0;
		if (countryRegionId) {
			api_getCitiesByCountryRegion(countryRegionId).then(json => {
				const citiesJson = json.items.map(city => {
					return { label: city.nm, value: city.nm };
				});
				setCities(citiesJson);
			});
		}

		api_getPostalCodes()
			.then(json => {
				const postalCodesJson = json.items.map(postalCode => {
					return { label: postalCode.cd, value: postalCode.cd };
				});
				setPostalCodes(postalCodesJson);
			})
			.catch(err => console.log(err));
	}, [metaData.billingCountryId, metaData.billingRegionId]);

	return (
		<div className="input-container">
			<div className="billing-input">
				{!UserSession.isAuthenticated() && (
					<input type="text" placeholder="Email" onChange={e => setMetaData({ ...metaData, billingEmail: e.target.value })} />
				)}
				{inputErrors?.billingEmail && <span className="input-error">{t(inputErrors?.billingEmail)}</span>}
			</div>

			<div className="billing-input">
				<input
					type="text"
					placeholder={t('first_name')}
					value={metaData.billingName}
					onChange={e => setMetaData({ ...metaData, billingName: e.target.value })}
				/>
				{inputErrors?.billingName && <span className="input-error">{t(inputErrors?.billingName)}</span>}
			</div>

			<div className="billing-input">
				<input
					type="text"
					placeholder={t('last_name')}
					value={metaData.billingLastname}
					onChange={e => setMetaData({ ...metaData, billingLastname: e.target.value })}
				/>
				{inputErrors?.billingLastname && <span className="input-error">{t(inputErrors?.billingLastname)}</span>}
			</div>

			<div className="billing-input phone-input">
				<PhoneInput
					country={'gr'}
					value={metaData.billingTelephone}
					countryCodeEditable={false}
					defaultMask=".............."
					alwaysDefaultMask={true}
					inputProps={{
						name: 'phone',
						required: true
					}}
					onChange={(value, country, e, formattedValue) => {
						setMetaData({ ...metaData, billingTelephone: value });
					}}
					containerClass="content-phone"
					inputClass="phone-input"
					required
				/>
				<label htmlFor="phone">{t('phone')}*</label>
				{inputErrors?.billingTelephone && <span className="input-error">{t(inputErrors?.billingTelephone)}</span>}
			</div>

			<div className="billing-input">
				<Select
					id="countrySelect"
					placeholder={t('country')}
					options={countries}
					onChange={e => setMetaData({ ...metaData, billingCountryId: e.value })}
					onMenuClose={() => unFocusSelect('countrySelect')}
					onMenuOpen={() => focusSelect('countrySelect')}
					styles={{ ...dropDownSelectStylesStatePC, marginBottom: 50 }}
					value={countries.find(country => country.value === metaData.billingCountryId) ?? 'Select'}
				/>
				{inputErrors?.billingCountryId && <span className="input-error">{t(inputErrors?.billingCountryId)}</span>}
			</div>

			{metaData.billingCountryId === 1 && regions.length > 0 && (
				<div className="billing-input">
					<Select
						id="regionSelect"
						placeholder={t('state')}
						options={regions}
						onChange={e => setMetaData({ ...metaData, billingRegionId: e.value })}
						onMenuClose={() => {
							unFocusSelect('regionSelect');
						}}
						onMenuOpen={() => {
							focusSelect('regionSelect');
						}}
						styles={dropDownSelectStylesStatePC}
						value={regions.find(region => region.value === metaData.billingRegionId) || ''}
					/>
					{inputErrors?.billingRegion && <span className="input-error">{t(inputErrors?.billingRegion)}</span>}
				</div>
			)}

			{metaData.billingCountryId === 1 && cities.length > 0 && metaData.billingRegionId ? (
				<div className="billing-input">
					<Select
						id="citySelect"
						placeholder={t('city')}
						options={sortLabels(cities)}
						onChange={e => setMetaData({ ...metaData, billingCity: e.label })}
						onMenuClose={() => {
							unFocusSelect('citySelect');
						}}
						onMenuOpen={() => {
							focusSelect('citySelect');
						}}
						styles={dropDownSelectStylesStatePC}
						value={cities.find(city => city.label === metaData.billingCity) || ''}
					/>
					{inputErrors?.billingCity && <span className="input-error">{t(inputErrors?.billingCity)}</span>}
				</div>
			) : (
				<div className="billing-input">
					<input
						type="text"
						className={`billing-input ${inputErrors?.ct && 'error'}`}
						placeholder={t('city') + '*'}
						value={metaData.billingCity}
						onChange={e => setMetaData({ ...metaData, billingCity: e.target.value })}
					/>
					{inputErrors?.billingCity && <span className="input-error">{t(inputErrors?.billingCity)}</span>}
				</div>
			)}

			<div className="billing-input">
				{metaData.billingCountryId === 1 ? (
					<Select
						id="postalCodeSelect"
						placeholder={t('postal_code')}
						options={postalCodes}
						onChange={e => setMetaData({ ...metaData, billingPostalCode: e.value })}
						onMenuClose={() => unFocusSelect('postalCodeSelect')}
						onMenuOpen={() => focusSelect('postalCodeSelect')}
						styles={dropDownSelectStylesStatePC}
						value={postalCodes.find(postalCode => postalCode.value === metaData.billingPostalCode) ?? 'Select'}
					/>
				) : (
					<input
						type="text"
						placeholder={t('postal_code')}
						value={metaData.billingPostalCode}
						onChange={e => setMetaData({ ...metaData, billingPostalCode: e.target.value })}
					/>
				)}
				{inputErrors?.billingPostalCode && <span className="input-error">{t(inputErrors?.billingPostalCode)}</span>}
			</div>

			<div className="billing-input">
				<input
					type="text"
					placeholder={t('address')}
					value={metaData.billingAddress}
					onChange={e => setMetaData({ ...metaData, billingAddress: e.target.value })}
				/>
				{inputErrors?.billingAddress && <span className="input-error">{t(inputErrors?.billingAddress)}</span>}
			</div>
		</div>
	);
};

BillingInputs.propTypes = {
	metaData: PropTypes.object,
	setMetaData: PropTypes.func
};

export default BillingInputs;
