export const supportPaymentOptionsIds = [4, 6];

export const handleValidateBillingInformation = (billingInformation, payment) => {
	let errorProperties = {};

	if (!billingInformation.billingEmail || billingInformation.billingEmail.trim() == '') {
		errorProperties.billingEmail = 'required_field';
	}

	if (!billingInformation.billingCountryId) {
		errorProperties.billingCountryId = 'required_field';
	}

	if (!billingInformation.billingName || billingInformation.billingName.trim() == '') {
		errorProperties.billingName = 'required_field';
	}

	if (!billingInformation.billingLastname || billingInformation.billingLastname.trim() == '') {
		errorProperties.billingLastname = 'required_field';
	}

	if (!billingInformation.billingTelephone || billingInformation.billingTelephone.trim().length == '') {
		errorProperties.billingTelephone = 'required_field';
	}

	if (!billingInformation.billingCity || billingInformation.billingCity.trim() == '') {
		errorProperties.billingCity = 'required_field';
	}

	if (!billingInformation.billingAddress || billingInformation.billingAddress.trim() == '') {
		errorProperties.billingAddress = 'required_field';
	}

	if (!billingInformation.billingPostalCode || billingInformation.billingPostalCode.trim() == '') {
		errorProperties.billingPostalCode = 'required_field';
	}

	if (Object.keys(errorProperties).length != 0) {
		throw {
			message: 'complete_all_fields',
			payload: errorProperties
		};
	}

	if (payment == null || payment.id == null || payment.id == 0) {
		throw {
			message: 'Select Payment Method',
			payload: {}
		};
	}

	return true;
};
